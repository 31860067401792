import BaseModel from "./base.model";
import { environment } from "src/environments/environment";
import { UsersRoles } from "../constants/user-roles";
import { UserRanks } from "../constants/user-ranks";
import { SubscriptionStatus } from "../constants/subscription-states";

export interface Subscription {
  status: SubscriptionStatus;
  expiresOn: Date|string|null;
}

export default class User extends BaseModel {
  id?: string|null;
  role?: UsersRoles|null;
  username?: string|null;
  email?: string|null;
  password?: string|null;
  firstName?: string;
  lastName?: string|null;
  birthDate?: Date|string|null;
  registrationDate?: Date|string|null;
  rank?: UserRanks|null;
  phone?: string|null;
  referralCode?: string|null;
  subscription?: Subscription|null;
  balance?: number|null;
  language?: string|null;
  boards: string[]|null=null;
  avatar?: number|null;
  sponsor?: string| null;
  qualified?: number|null;
  subscribers?: number|null;
  circles?: number|null;
  active?: number|null;
  wGPD?: number|null;
  wGPTO?: number|null;
  wGPT?: number|null;
  wUSDT?: number|null;
  wEUR?: number|null;
  wGPTR?: number|null;
  wGPTTR?: number|null; 
  addresscryptoKASPA!:string;
  //TO DO: Da aggiungere su User
  wBTC?:number = 0;
  wKAS?:number = 0;
  wKASR?:number = 0;
  wKGPM?:number = 0;
  wBGPM?:number = 0;
  dailyKAS?:number  = 0;
  monthlyKasR:number = 0; // mounthly Kas Reward
  totalKasR:number = 0; // Total Kas Reward
  wMinUSDT:number=0;
  //--------------------
  twr?: number|null;
  reward?: number|null;
  mouthlyR?: number |null;
  addresscryptoOriginal?: string;
  addresscrypto?: string;
  landingCrypto?:string;
  qm:number=0;
  totalCommunityKAS:number=0;

  profileImagePath: string|undefined;
  public get profileImageUrl(): string|null {
    if (!!this.profileImagePath === false) {
      return 'assets/images/placeholders/avatar.svg';
    }
    return `${environment.apiBaseUrl}/${this.profileImagePath}`;
  }

  public static override fromObject(srcObj: any, instance: any, skipNull: boolean = true) {
    const model: User = BaseModel.fromObject(srcObj, new User());
    // fix
    return model;
  }
}
