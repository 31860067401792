<ion-content class="ion-no-padding transfer-item">
  <ion-grid>
    <ion-row class="top-row">
     
      <ion-col size="12">
        <ion-button (click)="cancel()" color="tranparent" class="ion-no-margin ion-no-padding back-button">
          <ion-icon src="../../../assets/icons/arrow-icon-left.svg" slot="icon-only" color="primary"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-title class="ion-text-center ion-margin-top ion-margin-bottom">
        My Purchase
      </ion-title>
    </ion-row>
    <app-slider-item [fixedAreaWidth]="0" 
                [fixedAreaMinWidth]="0" [sliderAreaWidth]="1000">
        <div blocked> 

        </div>
        <div slider class="container_slider"> 
        <ion-item style="--background: none;">
          <ion-grid > 
            <ion-row>
              <ion-col class = "col">
                Amount
              </ion-col>
              <ion-col class = "col">
                Date/Time
              </ion-col>
              <ion-col class = "col">
                TH/S
              </ion-col>
              <ion-col class = "col">
                Miner Id
              </ion-col>
              <ion-col class = "col">
                miner
              </ion-col>
              <ion-col class = "col">
                Purchase Id
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        <ion-item style="--background: none;" *ngFor="let  i of myPurchase">
          <ion-grid > 
            <ion-row>
              <ion-col class = "col">
               {{i.amount | number:'1.4-4'}} kGPM
              </ion-col>
              <ion-col class = "col">
                {{i.data | date:'YYYY-MM-dd HH:mm'}}
              </ion-col>
              <ion-col class = "col">
                {{i.th}}
               </ion-col>
              <ion-col class = "col">
                {{i.minerid}}
              </ion-col>
              <ion-col class = "col">
                {{i.miner }}
              </ion-col>
              <ion-col class = "col red">
                <a *ngIf="i.purchaseid!=null"
                    [href]="'https://kaspa-pool.org/#/dashboard/kaspa:qrl38glvtg2phssnnrl6da4zjxwqtyeu73zvduettdt08k7yqd6hydafe4hhm'+i.purchaseid">
                      {{i.purchaseid}}
                </a>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
       
        </div>
    </app-slider-item>
  </ion-grid>
</ion-content>