import { Injectable, OnInit } from '@angular/core';
import User from '../models/user';
import { GPTService } from './gpt.service.';
import { AuthService } from './auth.service';
import { UsersService } from './users.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import WalletUser from '../models/userwallet.model';
import { WalletService } from './wallet.service';

@Injectable({
  providedIn: 'root'
})
export class DatasessionService{

  coinValues: Array<Coin> = new Array<Coin>();
  wallets:Array<Wallet> = new Array<Wallet>();

  gptMax:number = 0;
  usdtMax:number = 0;
  usdtMiningMax:number=0;
  eurMax:number = 0;
  kasMax:number = 0;
  kgpmMax:number = 0;
  bgpmMax:number = 0;
  btcMax:number = 0;
  
  interval:any;
  walletUser:WalletUser = new WalletUser();
  loggedUser:User|null = null; 
   
  public gptMax$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public usdtMax$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public usdtMiningMax$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

  public eurMax$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public kasMax$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public kgpmMax$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public bgpmMax$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public btcMax$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  
  public totalReward$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public monthlyReward$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public walletReward$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public gptToDollar$:BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public kasToDollar$:BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public walletSpot$:BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public walletUser$:BehaviorSubject<WalletUser | null> = new BehaviorSubject<WalletUser | null>(null);
  public loggedUser$:BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  s?:Subscription;
  constructor(private GptService: GPTService, 
              private authService:AuthService, 
              private walletService:WalletService,
              private userSercvices:UsersService) { 
    this.init();
  }


  init(){
    

    //TODO: impostare i MAX (dipende dal BE)
    this.coinValues = new Array<Coin>();
    this.coinValues.push(new Coin( 'GPT','GPT','gpt', 0, 4,'../../../assets/icons/gpt-icon.svg', this.gptMax, 6,'GPT'));
    this.coinValues.push(new Coin('USDT','USDT','usdt', 0, 4,'../../../assets/icons/usdt-icon.svg', this.usdtMax, 0,'$'));
      //pagesId, text, value, coinVal, decimals, icon, maxavailable, swapfee, symbol
    this.coinValues.push(new Coin("EUR",'EURO','euro',0,4,'../../../assets/icons/euro-icon.svg',this.eurMax,0,'€'))
    /* NEW RELEASE*/
    this.coinValues.push(new Coin("KAS",'KAS','kas',0.163518,6,'../../../assets/icons/kas.svg',this.eurMax,0.16,'kas'))
    this.coinValues.push(new Coin("KGPM",'KGPM','kgpm',0,6,'../../../assets/icons/kgpm.svg',this.eurMax,1,'kgpm'))
    this.coinValues.push(new Coin("BGPM",'BGPM','bgpm',0,6,'../../../assets/icons/bgpm.svg',this.eurMax,1,'bgpm'))
    this.coinValues.push(new Coin("BTC",'BTC','btc',58581.06,6,'../../../assets/icons/btc.svg',this.eurMax,1,'btc'))
    
    this.wallets = new Array<Wallet>();
    this.wallets.push(new Wallet("spot","Wallet Spot"));
    this.wallets.push(new Wallet("reward","Wallet Reward"));
    this.wallets.push(new Wallet("pool","Wallet Pool"));
    this.wallets.push(new Wallet("mining","Wallet Mining"));
    
    /* NEW RELEASE*/
    this.wallets.push(new Wallet("walletMining","Wallet Mining"));
    
    
    this.mainWork();
    //imposto la funzione di conversione per gli USDT
    this.coinValues[1].conversionFunction = (value:number)=>{
      return value / this.coinValues[1].coinValue;
    }
    //clearTimeout(this.timeout);

     

    this.gptMax$.subscribe((val)=>{
      this.coinValues[0].maxAvailable =this.gptMax;
     
    });
    this.usdtMax$.subscribe(()=>{
      this.coinValues[1].maxAvailable = this.usdtMax;
    })

    this.usdtMiningMax$.subscribe(()=>{
      //this.coinValues[1].maxAvailable = this.usdtMax;
    })

    this.kasMax$.subscribe(()=>{
      this.coinValues[3].maxAvailable = this.usdtMax;
    })

    this.kgpmMax$.subscribe(()=>{
      this.coinValues[4].maxAvailable = this.usdtMax;
    })
    this.bgpmMax$.subscribe(()=>{
      this.coinValues[5].maxAvailable = this.usdtMax;
    })
    this.btcMax$.subscribe(()=>{
      this.coinValues[6].maxAvailable = this.usdtMax;
    })
  }
mainWork(){
  this.getUser();
  this.interval = setInterval(
    ()=>{
      this.getUser();
      this.getConversionValues();
    },5000)

}
getConversionValues(){
   this.GptService.getValueGPT().then(v=>{
    this.gptToDollar$.next(v);    
    this.coinValues[0].coinValue = v;
    this.coinValues[1].coinValue = v;
  });
  this.walletService.priceKAS().then(
    v=>{
      if(v!=null){
        this.kasToDollar$.next(v);
        this.coinValues[3].coinValue = v;
      }
      else
        console.error("Kas value reading is returning null");
    }

  )
  
}
getUser(){
   if(this.authService.loggedUser$.getValue() == null)
    return;
    this.userSercvices.get(this.authService.loggedUser$.getValue()?.id).then((user: User|null) => {
    this.loggedUser = user;
    this.loggedUser$.next(user);
    //console.log(user)
    if(user){
      this.gptMax = user!.wGPT as number; //realmente disponibile walletSpot
      this.usdtMax = user!.wUSDT as number; //wallet USDT
      this.usdtMiningMax = user!.wMinUSDT as number; //wallet USDT
      this.eurMax = user!.wEUR as number;//wallet eur
      this.kasMax = user!.wKAS as number;//wallet eur
      this.kgpmMax = user!.wKGPM as number;//wallet eur
      this.bgpmMax = user!.wBGPM as number;//wallet eur
      this.btcMax = user!.wBTC as number;//wallet eur

      this.gptMax$.next(this.gptMax);
      this.usdtMax$.next(this.usdtMax);
      this.usdtMiningMax$.next(this.usdtMiningMax);
      this.eurMax$.next(this.eurMax);
      this.kasMax$.next(this.kasMax);
      this.kgpmMax$.next(this.kgpmMax);
      this.bgpmMax$.next(this.bgpmMax);
      this.btcMax$.next(this.btcMax);


      this.walletSpot$.next(user.wGPT as number);
      this.monthlyReward$.next(user.mouthlyR as number);
      this.totalReward$.next(user.wGPTTR as number);
      this.walletReward$.next(user.wGPTR as number);

      this.walletUser.gptReward = user.wGPTR as number;
      this.walletUser.kasReward = user.wKASR as number;
      this.walletUser.dailyK = user.dailyKAS as number;
      this.walletUser.monthlyK = user.monthlyKasR as number;
      this.walletUser.wMinUSDT = user.wMinUSDT as number;
      this.walletUser.eur = user.wEUR as number;
      this.walletUser.usdt = user.wUSDT as number;
      this.walletUser.gptO = user.wGPTO as number;
      this.walletUser.btc = user.wBTC as number;
      this.walletUser.kas = user.wKAS as number;
      this.walletUser.bgpm = user.wBGPM as number;
      this.walletUser.kgpm = user.wKGPM as number;
      this.walletUser.wMinUSDT = user.wMinUSDT as number;
      
      this.walletUser$.next(this.walletUser);

      let a = user.reward //espresso in USDT
    }    
    //tengo aggiornato il valore del GPT
    this.coinValues[0].maxAvailable = this.gptMax;
    this.coinValues[1].maxAvailable = this.usdtMax; 
    this.coinValues[2].maxAvailable = this.eurMax;
    this.coinValues[3].maxAvailable = this.kasMax;
    this.coinValues[4].maxAvailable = this.kgpmMax;
    this.coinValues[5].maxAvailable =this.bgpmMax;
    this.coinValues[6].maxAvailable = this.btcMax;
  });
}
  reset(){
    //let user = this.authService.loggedUser$.getValue();
    let gptMax = 0; //user!.wGPT as number;
    let usdtMax = 0; //user!.wUSDT as number;
    this.coinValues[0].maxAvailable = 0; //gptMax;
    this.coinValues[1].maxAvailable = 0; //usdtMax;
    this.coinValues[2].maxAvailable = 0;
    sessionStorage.clear();
    this.authService.restoreDefaultUser();
  }
  getMaxValue(){
    this.coinValues[0].maxAvailable = 0; //gptMax;
    this.coinValues[1].maxAvailable = 0; //usdtMax;
    this.coinValues[2].maxAvailable = 0; //eurMax;
  }
  getCoinsByIds(Ids:string[]):Coin[]{
    let coins = this.coinValues.filter(c=>Ids.indexOf(c.id)>-1 || Ids[0] == "all");
    coins = coins.sort((a,b)=>{
      //riordino in modo che l'ordine finale corrisponda all'ordine degli id in input
      return Ids.indexOf(a.id) > Ids.indexOf(b.id)?  -1:1
     })
    return coins;
  }
  getWalletByIds(Ids:string[]):Wallet[]{
    let wallets = []; 
        wallets = this.wallets.filter(c=>Ids.indexOf(c.id)>-1 || Ids[0] == "all");
        wallets = wallets.sort((a,b)=>{
          //riordino in modo che l'ordine finale corrisponda all'ordine degli id in input
         return Ids.indexOf(a.id) > Ids.indexOf(b.id)?  1:-1
        })
    return wallets;
  }
}

export class Coin{
  constructor(
      id:string,
      text:string, 
      value:string, 
      coinVal:number, 
      decimals:number, 
      icon:string, 
      maxavailable:number, 
      swapFee:number = 0, 
      symbol=''){
    this.id = id;
   
    this.symbol = symbol;
    this.text = text;
    this.value = value;
    this.coinValue = coinVal;
    this.icon = icon;
    this.decimals = decimals;
    this.maxAvailable = maxavailable;
    this.swapFeePercent = swapFee;
  }
  id:string="";
  symbol:string = '';
  swapFeePercent:number = 0;
  decimals:number = 2;
  text!: string;
  value!:string;
  coinValue!:number;
  icon!: string;
  maxAvailable:number = 0;
  conversionFunction?:any = (value:number)=>{
    return value*this.coinValue;
  };
}

export class Wallet{
  constructor(id:string,description:string){
    this.id = id;
    this.description = description;
  }
  id:string = "";
  description:string = "";
}
