import { WalletService } from 'src/app/services/wallet.service';

import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons/base/base.component';
import { MyPurchaseItem } from './model/MyPurchaseItem';
import { AuthService } from 'src/app/services/auth.service';
import { DatasessionService } from 'src/app/services/datasession.service';
import { UsersService } from 'src/app/services/users.service';
import { CommonService } from 'src/app/commons/commons.service';

@Component({
  selector: 'app-mining-purchase-modal',
  templateUrl: './mining-purchase-modal.component.html',
  styleUrls: ['./mining-purchase-modal.component.scss'],
})
export class MiningPurchaseModalComponent extends BaseComponent implements OnInit {

  constructor(
    private modalController:ModalController,
    private _authServices:AuthService,
    private _dataSessionServices:DatasessionService,
    private _userServices:UsersService,
    private commonsServices:CommonService,
    private walletServices:WalletService
  ) { 
    super(_authServices,_dataSessionServices,_userServices)
  }

  myPurchase:MyPurchaseItem[] = [];
  override async ngOnInit() {
    this.id = "MiningPurchaseModalComponent";
    await super.ngOnInit();
    
  }
  override onComponentIsReady(): void {
    super.onComponentIsReady();
    this.walletServices.myPourchase(this.loggedUser!.id as string).then((r:any)=>{
      if(r.success){
        this.myPurchase = r.data;
      }
      else
        this.commonsServices.showToast("sendrerror","Something bad appened! "+r.message,4000,"bottom","danger");
    }).catch((error)=>{
      this.commonsServices.showToast("senderror","Something bad appened! "+error,4000,"bottom","danger");
    });
  }
  cancel(){
    this.modalController.dismiss(null,"cancel",this.id).then();
  }
}
