<ion-content class="ion-no-padding transfer-item">
  <ion-grid>

    <ion-row class="top-row">
      <ion-col size="12">
        <ion-button (click)="cancel()" color="tranparent" class="ion-no-margin ion-no-padding back-button">
          <ion-icon src="../../../assets/icons/arrow-icon-left.svg" slot="icon-only" color="primary"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row class="middle-row">
      <ion-col size="12">
        <ion-title class="ion-text-center ">
          TRANSFER
        </ion-title>

        <div class="gap30"></div>

        <ion-item lines="none" class="input-item">
          <ion-select [(ngModel)]="selectedFrom" interface="popover" 
          label="from" labelPlacement="start" placeholder="Wallet" 
            class="input-select"
            toggleIcon="../../../assets/icons/arrow-icon-down.svg"
            expandedIcon="../../../assets/icons/arrow-icon-up.svg"
          >
            <ion-select-option *ngFor="let fromVal of fromWallets" 
                                [value]="fromVal">
              {{ fromVal.description }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <div class="switch-icon-wrapper" (click)="switch()" *ngIf="reverse">
          <ion-icon src="../../../assets/icons/switch-icon.svg" class="switch-icon"></ion-icon>
        </div>


        <ion-item lines="none" class="input-item ion-margin-top">
          <ion-select [(ngModel)]="selectedTo" interface="popover" 
            label="to" labelPlacement="start" placeholder="Wallet" 
            class="input-select"
            toggleIcon="../../../assets/icons/arrow-icon-down.svg"
            expandedIcon="../../../assets/icons/arrow-icon-up.svg">
            <ion-select-option *ngFor="let toVal of toWallets" 
                                [value]="toVal">
              {{ toVal.description }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <div class="gap30"></div>

        <ion-label class="label-external">Coin</ion-label>
        <ion-item lines="none" class="input-item coin-item">
          <ion-button class="start-item" slot="start" 
            (click)="triggerSelectCoin($event)">
            <ion-icon *ngIf="!!selectedCoin?.icon === true" slot="start" [src]="selectedCoin?.icon"></ion-icon>
            <ion-icon *ngIf="!!selectedCoin?.icon === false" slot="start" name="open"></ion-icon>
            <ion-label style="color: #f3f3f0">{{ selectedCoin?.text ?? 'Select...' }}</ion-label>
          </ion-button>

          <ion-select slot="end" #inputSelectCoin [(ngModel)]="selectedCoin" interface="popover" label="Select coin.." labelPlacement="start" placeholder="Select from..."
            class="input-select input-select-coin hide-label hide-placeholder hide-text"
            toggleIcon="../../../assets/icons/arrow-icon-down.svg"
            expandedIcon="../../../assets/icons/arrow-icon-up.svg">
            <ion-select-option *ngFor="let coinVal of coins" [value]="coinVal">
              <ion-item>
                {{ coinVal.text }}
              </ion-item>
            </ion-select-option>
          </ion-select>

        </ion-item>

        <div class="gap20"></div>


        <ion-label class="label-external">Amount</ion-label>
        <ion-item lines="none" class="input-item amount-item">
          <input [(ngModel)]="amount"                   
                    type="number"
                    (focus)= "inputIsOnFocus = true"
                    (blur) = "inputIsOnFocus = false; onValueChange()"                  
                    placeholder="Please enter amount to be transfered" 
                    class="amount-input input-value"
                    [class.red]="availableAmountError" #fromvalue />
          
          <div slot="end" class="end-slot ion-text-right" style="margin: 0px; width: 100px;">
              <ion-item *ngIf="!!selectedCoin" color="transparent" lines="none" class="ion-float-right ion-float-right ion-no-padding">
                <ion-grid style="padding:0px;">
                  <ion-row style="height: 24px;">
                    <ion-col style="margin:0px; ">
                      <ion-label class="ion-no-margin">
                        {{ selectedCoin?.text ?? '' }}
                      </ion-label>
                    </ion-col>
                    <ion-col style="margin:0px; padding-top: 8px; cursor:pointer;" (click)="max()">
                      <ion-label class="mt-1 detail" >Max</ion-label>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col  style="margin:0px; " class="value" *ngIf="coinId!='USDT'">
                      {{currencyUSDT  | number:'1.4-4'}} <small>USDT</small>
                    </ion-col>
                    <ion-col  style="margin:0px; " class="value" *ngIf="coinId=='USDT'">
                      {{currencyUSDT  | number:'1.4-4'}} <small>GPT</small>
                    </ion-col>
                  </ion-row>
                </ion-grid>    
              </ion-item>
          </div>
        </ion-item>


        <div style="margin-top:8px;">
          <ion-label class="label-external ion-float-left">Available</ion-label>
          <ion-label class="label-external ion-float-right label-small">
             {{maxAvailable | number:'1.4-4'}} <small>{{selectedCoin.description}}</small>
          </ion-label>
        </div>
      </ion-col>


    </ion-row>

    <ion-row class="bottom-row">

      <ion-col size="12" class="ion-text-center">
        <ion-button [disabled]="availableAmountError" (click)="confirm()" class="confirm-button">
          <ion-label>CONFIRM</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

