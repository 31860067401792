import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

//#region - Http Verbs Enum
/**
 * Http Verbs Enumeration *
 * See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods
 */
export enum HttpVerbs {

  /**
   * The GET method requests a representation of the specified resource. Requests using GET should only retrieve data.
   */
  GET = 'get',

  /**
   * The HEAD method asks for a response identical to a GET request, but without the response body.
   */
  HEAD = 'head',

  /**
   * The POST method submits an entity to the specified resource, often causing a change in state or side effects on the server.
   */
  POST = 'post',

  /**
   * The PUT method replaces all current representations of the target resource with the request payload.
   */
  PUT = 'put',

  /**
   * The DELETE method deletes the specified resource.
   */
  DELETE = 'delete',

  /**
   * The CONNECT method establishes a tunnel to the server identified by the target resource.
   */
  CONNECT = 'connect',

  /**
   * The OPTIONS method describes the communication options for the target resource.
   */
  OPTIONS = 'options',

  /**
   * The TRACE method performs a message loop-back test along the path to the target resource.
   */
  TRACE = 'trace',

  /**
   * The PATCH method applies partial modifications to a resource.
   */
  PATCH = 'patch',

}
//#endregion - Http Verbs Enum

//#region - Main Service
@Injectable({
  providedIn: 'root'
})
export class NGHttpService {

  /**
   * @property {string} apiBaseUrl - The api base use is searched by default to environment file, but can be overrided as is a public property
   */
  public apiBaseUrl: string = !!environment.apiBaseUrl ? environment.apiBaseUrl : '/';

  public httpErrors$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private httpClient: HttpClient,
  ) { }

  async execute(
    httpVerb: string,
    endPoint: string,
    data: any|FormData|string|null = {},
    mapCallback: CallableFunction|null = (item: any) => item,
    getTokenCallback: CallableFunction|null = () => '',
    customHeaders: any|null = null
  ): Promise<any|Array<any>|null|boolean> {

    const token: string|null = !!getTokenCallback ? await getTokenCallback() : null;

    try {

      // Remove createdAt/updatedAt properties will be populated server side
      if (!!data && !!data.createdAt) { delete data.createdAt; }
      if (!!data && !!data.updatedAt) { delete data.updatedAt; }

      // Auto-Magically call right method
      const response: any = await (this as any)[httpVerb](token, endPoint, (data ?? {}), customHeaders);

      // Handle unespected/errors response
      if (!!response === false || !!response.error) {
        console.log('HttpProxyService.execute() [unespected/errors response] -> ', response);
        return response;
      }

      // No mapCallback, return response as is
      if (!!mapCallback === false) {
        return response;
      }

      // Map array of items
      if (Array.isArray(response) && !!mapCallback) {
        return response.map((item: any) => mapCallback(item))
      }

      // Map single item
      return (!!mapCallback ? mapCallback(response) : response);

    } catch (error: any) {
      console.log('HttpProxyService.execute() [catch] -> ', error);
      return null;
    }
  }

  buildRequestUrl(endPointOrUrl: string) {
    let requestUrl: string = `${this.apiBaseUrl}${endPointOrUrl}`;

    // Override when arbitrary URL is passed
    if (endPointOrUrl.indexOf('https://') > -1 || endPointOrUrl.indexOf('http://') > -1) {
      requestUrl = endPointOrUrl;
    }

    return requestUrl;
  }

  async get(token: string, endPointOrUrl: string, data: any = {}, customHeaders: any|null = null): Promise<any | boolean> {
    return new Promise(async (resolve) => {
      const defaultHeaders: any = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      };

      const httpOptions = {
        headers: new HttpHeaders(customHeaders ?? defaultHeaders),
        params: data
      };
      const requestUrl = this.buildRequestUrl(endPointOrUrl);
      this.httpClient.get<any>(requestUrl, httpOptions)
        .subscribe({
          next: (response: any) => {
            if (!!response?.error) {
              console.log('then() error', response);
            }
            return resolve(response);
          },
          error: (error: any) => {
            console.log(error);
            this.httpErrors$.next({
              error,
              token,
              endPoint: endPointOrUrl,
              data,
              customHeaders,
            });
            return resolve(error.error);
          },
          complete: () => { }
        });
    });
  }

  async post(token: string, endPointOrUrl: string, data: any = {}, customHeaders: any|null = null): Promise<any> {
    return new Promise(async (resolve) => {
      const defaultHeaders: any = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      };

      const httpOptions = {
        headers: new HttpHeaders(customHeaders ?? defaultHeaders),
      };
      const requestUrl = this.buildRequestUrl(endPointOrUrl);

      // Remove automatic timestamps fields (must be handled on server side)
      delete data.deletedAt;
      delete data.createdAt;
      delete data.updatedAt;

      this.httpClient.post(requestUrl, data, httpOptions)
        .subscribe({
          next: (response: any) => {
            if (!!response?.error) {
              console.log('then() error', response);
            }
            return resolve(response);
          },
          error: (error: any) => {
            console.log(error);
            this.httpErrors$.next({
              error,
              token,
              endPoint: endPointOrUrl,
              data,
              customHeaders,
            });
            return resolve(error.error);
          },
          complete: () => { }
        });
    });
  }

  async patch(token: string, endPointOrUrl: any, data: any = {}, customHeaders: any|null = null): Promise<any> {
    return new Promise(async (resolve) => {
      const defaultHeaders: any = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      };

      const httpOptions = {
        headers: new HttpHeaders(customHeaders ?? defaultHeaders),
      };
      const requestUrl = this.buildRequestUrl(endPointOrUrl);

      // Remove automatic timestamps fields (must be handled on server side)
      delete data.deletedAt;
      delete data.createdAt;
      delete data.updatedAt;

      this.httpClient.patch(requestUrl, data, httpOptions)
        .subscribe({
          next: (response: any) => {
            if (!!response?.error) {
              console.log('then() error', response);
            }
            return resolve(response);
          },
          error: (error: any) => {
            console.log(error);
            this.httpErrors$.next({
              error,
              token,
              endPoint: endPointOrUrl,
              data,
              customHeaders,
            });
            return resolve(error.error);
          },
          complete: () => { }
        });
    });
  }

  async delete(token: string, endPointOrUrl: string, data: any = {}, customHeaders: any|null = null): Promise<any> {
    const defaultHeaders: any = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };

    return new Promise(async (resolve) => {
      const httpOptions = {
        headers: new HttpHeaders(customHeaders ?? defaultHeaders),
        body: data
      };
      const requestUrl = this.buildRequestUrl(endPointOrUrl);
      this.httpClient.delete(requestUrl, httpOptions)
        .subscribe({
          next: (response: any) => {
            if (!!response?.error) {
              console.log('then() error', response);
            }
            return resolve(response);
          },
          error: (error: any) => {
            console.log(error);
            this.httpErrors$.next({
              error,
              token,
              endPoint: endPointOrUrl,
              data,
              customHeaders,
            });
            return resolve(error.error);
          },
          complete: () => { }
        });
    });
  }

  async head(token: string, endPointOrUrl: string, data: any = {}, customHeaders: any|null = null): Promise<any | boolean>  {
    throw new Error('Not implemented error');
  }

  async put(token: string, endPointOrUrl: string, data: any = {}, customHeaders: any|null = null): Promise<any | boolean>  {
    return new Promise(async (resolve) => {
      const defaultHeaders: any = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      };

      const httpOptions = {
        headers: new HttpHeaders(customHeaders ?? defaultHeaders),
      };
      const requestUrl = this.buildRequestUrl(endPointOrUrl);

      // Remove automatic timestamps fields (must be handled on server side)
      delete data.deletedAt;
      delete data.createdAt;
      delete data.updatedAt;

      this.httpClient.put(requestUrl, data, httpOptions)
        .subscribe({
          next: (response: any) => {
            if (!!response?.error) {
              console.log('then() error', response);
            }
            return resolve(response);
          },
          error: (error: any) => {
            console.log(error);
            this.httpErrors$.next({
              error,
              token,
              endPoint: endPointOrUrl,
              data,
              customHeaders,
            });
            return resolve(error.error);
          },
          complete: () => { }
        });
    });
  }

  async connect(token: string, endPointOrUrl: string, data: any = {}, customHeaders: any|null = null): Promise<any | boolean>  {
    throw new Error('Not implemented error');
  }

  async options(token: string, endPointOrUrl: string, data: any = {}, customHeaders: any|null = null): Promise<any | boolean>  {
    throw new Error('Not implemented error');
  }

  async trace(token: string, endPointOrUrl: string, data: any = {}, customHeaders: any|null = null): Promise<any | boolean>  {
    throw new Error('Not implemented error');
  }

}
//#endregion - Main Service
