<ion-header [translucent]="false">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-buttons slot="primary" >
      <ion-button [routerLink]="['/auth/profile']">
        <ion-icon slot="icon-only" name="person-circle"></ion-icon>
      </ion-button>
      <ion-button id="button">
        <ion-icon slot="icon-only" ios="ellipsis-vertical" md="ellipsis-vertical"></ion-icon>
      </ion-button>
      <!--<ion-button>
        <ion-icon slot="icon-only" name="search"></ion-icon>
      </ion-button>-->
    </ion-buttons>

    <ion-title class="ion-text-right">      
      <ion-label class="normal-case gpt-currency" >GPT</ion-label>
      <span class="gpt-quota" >{{ gptToDollar }} 
        <small style="font-family:'Bahnschrift';color:rgba(255,255,255,0.7)">$</small></span>
        <span *ngIf="!prod"class="gpt-quota" >SVI</span>  
    </ion-title>
  </ion-toolbar>

  <ion-popover trigger="button" side="bottom" backdropDismiss="true" dismissOnSelect="true">
    <ng-template>
      <ion-content>
        <ion-item  [routerLink]="['/auth/profile']">
          <ion-icon name="person-circle"></ion-icon>
          &nbsp; Profile
        </ion-item>
        <ion-item  (click) = "copy()">
          <img src="assets/images/referral-icon.svg" 
              style="width: 24px; height: 24px;">
          &nbsp; Share
        </ion-item>
        <ion-item (click)="logout()">
              <img src="assets/images/dashboard/icon-exit.svg" 
                         style="width: 24px; height: 24px;">
              &nbsp; Logout
        </ion-item>
      </ion-content>
    </ng-template>
  </ion-popover>
</ion-header>
