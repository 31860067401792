import { GPTService } from 'src/app/services/gpt.service.';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, PopoverController } from '@ionic/angular';
import User from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { AppComponent } from 'src/app/app.component';
import { CommonService } from 'src/app/commons/commons.service';
import { environment } from 'src/environments/environment';
import { DatasessionService } from 'src/app/services/datasession.service';
import { BaseComponent } from 'src/app/commons/base/base.component';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss'],
})
export class HeaderMainComponent extends BaseComponent implements OnInit {

  @Input() title: string = '';
  @Input() hideAvatar: boolean = false;

  
  avatarSrc: string = '/assets/images/placeholders/avatar.svg';
  
  testing:boolean=false;
  defaultUser: string | null;
  prod:boolean = environment.production;
  constructor(
    private popoverCtrl:PopoverController,
    private _dataSessionService:DatasessionService,
    private _userService:UsersService,
    private readonly router: Router,
    private readonly loadingController: LoadingController,
    private readonly _authService: AuthService,
    private commonService: CommonService,
    private appComponent: AppComponent,
    private GptService: GPTService
  ) {
      super(_authService,_dataSessionService,_userService)
    this.loggedUser = this._authService.loggedUser$.getValue();
    this.testing= appComponent.testing;
    this.defaultUser=(this.loggedUser?.username==null ? "" :  this.loggedUser?.username);
    this._authService.loggedUser$.subscribe((user: User|null) => {
      this.loggedUser = user;      
    });
  }

  /*async goTesting(){
    this.testing=!this.testing;
    this.appComponent.testing=this.testing;

    if (this.testing){
      await this._authService.restoreDefaultUser();
    }
  }*/
  copy(){
   
    if (!!this.loggedUser)
      this.copyTextToClipboard(`${environment.appUrl}auth/register/${this.loggedUser.referralCode}`);
  }

  copyTextToClipboard(text: string) {
    // Verifica se il browser supporta l'API Clipboard
    if (!navigator.clipboard) {
        console.error("Clipboard API non supportata");
        return;
    }

    // Copia il testo negli appunti del sistema
    navigator.clipboard.writeText(text)
        .then(() => {
            console.log("Testo copiato negli appunti: ",text);
            this.commonService.showToast("clipboardToast","Copied",4000,"bottom","success")
        })
        .catch((err) => {
            console.error("Errore durante la copia del testo negli appunti:", err);
        });
}

  async logout() {
    const loading = await this.loadingController.create({ message: 'Operation in progress...' });
    await loading.present();
    await this._authService.logout();    
    await loading.dismiss();
    await this.router.navigate(['/']);
  }

}
