<ion-content class="ion-no-padding transfer-item">
  <ion-grid>

    <ion-row class="top-row">
      <ion-col size="12">
        <ion-button (click)="cancel()" color="tranparent" class="ion-no-margin ion-no-padding back-button">
          <ion-icon src="../../../assets/icons/arrow-icon-left.svg" slot="icon-only" color="primary"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row class="middle-row">
      <ion-col size="12">
        <ion-title class="ion-text-center ion-margin-top ion-margin-bottom">
          {{title}}
        </ion-title>

        <div class="gap15"></div>
        <ion-row>
          <ion-col>
            <ion-label class="label-external label-on-top" >Account</ion-label>
          </ion-col>
          <ion-col size="4" style="text-align: end;">
            <ion-label class="label-external label-on-top">
              Spot Account &nbsp;
              <img src="../../../assets/icons/arrow-icon-down.svg" class = "arrow-down-spot-account"/>
            </ion-label>
          </ion-col>
        </ion-row>        
        
        <ion-item lines="none" class="input-item coin-item">
          
          <ion-button class="start-item" slot="start" (click)="triggerSelectCoin($event)">
            <ion-icon class="start-item-icon" *ngIf="!!selectedCoinFrom?.icon === true" slot="start" [src]="selectedCoinFrom?.icon"></ion-icon>
            <ion-icon class="start-item-icon" *ngIf="!!selectedCoinFrom?.icon === false" slot="start" name="open"></ion-icon>
            <ion-label class="placeholder-select-start">from</ion-label>
            <ion-label class = "start-item-description" >{{ selectedCoinFrom?.text ?? 'From' }}</ion-label>
          </ion-button>

          <ion-select slot="end" 
                      #inputSelectCoin 
                      [(ngModel)]="selectedCoinFrom"                       
                      interface="popover" label="Select coin.." 
                      labelPlacement="start"
                      class="input-select input-select-coin hide-label hide-placeholder hide-text"
                      toggleIcon="../../../assets/icons/arrow-icon-down.svg"
                      expandedIcon="../../../assets/icons/arrow-icon-up.svg">
                      <ion-select-option 
                          *ngFor="let coinVal of coinsFrom" 
                          [value]="coinVal">
                        <ion-item >
                          {{ coinVal.text }}
                        </ion-item>
                      </ion-select-option>
          </ion-select>
            <div class="select-details-end">
              <ion-row style="height: 11px; width:243px">
                <ion-col size="4">
                  &nbsp;
                </ion-col>
                <ion-col  size="4" style="max-width: 81px!important;">
                  <ion-label class="placeholder-select-end">
                      Available Balance
                  </ion-label>
                </ion-col>
                <ion-col size="4">
                  <ion-label class="placeholder-select-end-gpt">
                    {{this.maxAvailable}} {{this.selectedCoinFrom?.value}}
                  </ion-label>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="5">
                  &nbsp;
                </ion-col>
                <ion-col size="4" style="max-width: 81px!important;">
                  <ion-label class="available-balance-value">
                    <input #fromvalue 
                          type="number"
                           [(ngModel)]="amountFrom" 
                           (focus)="inputIsOnFocus = true"
                           (blur)="inputIsOnFocus=false;
                           onValueChange();"
                           [class.red]="availableAmountError"
                           class="input-value"/>
                  </ion-label>
                </ion-col>
                <ion-col size="1" style="font-weight: 100; font-family: 'AcquireLight';">
                              &nbsp;
                </ion-col>
                <ion-col size="2" class="label-max" (click)="max()">
                    Max
                </ion-col>  
              </ion-row>
            </div>

        </ion-item>

        <div class="switch-icon-wrapper" (click)="swap()" *ngIf="swapReverse">
          <ion-icon src="../../../assets/icons/switch-icon.svg" class="switch-icon"></ion-icon>
        </div>

        <div class="gap20"></div>

        <ion-item lines="none" class="input-item coin-item">
          <ion-button class="start-item" slot="start" (click)="triggerSelectCoin($event)">
            <ion-icon class="start-item-icon" *ngIf="!!selectedCoinTo?.icon === true" slot="start" [src]="selectedCoinTo?.icon"></ion-icon>
            <ion-icon class="start-item-icon" *ngIf="!!selectedCoinTo?.icon === false" slot="start" name="open"></ion-icon>
            <ion-label class="placeholder-select-start">to</ion-label>
            <ion-label class = "start-item-description" style="color:#f3f3f0">{{ selectedCoinTo?.text ?? 'To' }}</ion-label>
          </ion-button>

          <ion-select slot="end" 
                      #inputSelectCoin 
                      [(ngModel)]="selectedCoinTo" interface="popover" label="Select coin.." labelPlacement="start" 
                      class="input-select input-select-coin hide-label hide-placeholder hide-text"
                      toggleIcon="../../../assets/icons/arrow-icon-down.svg"
                      expandedIcon="../../../assets/icons/arrow-icon-up.svg">
            <ion-select-option *ngFor="let coinVal of coinsTo" [value]="coinVal">
              <ion-item>
                {{ coinVal.text }}
              </ion-item>
            </ion-select-option>
          </ion-select>
          <div class="select-details-to-end">
             <ion-row>
              <ion-col size="6">
                &nbsp;
              </ion-col>
              <ion-col size="5" style="max-width: 81px!important;">
                <!--<ion-label class="available-balance-value" *ngIf="selectedCoinTo?.decimals==2">
                      {{amountTo | number: '1.2-2'}}
                </ion-label>-->
                <ion-label class="available-balance-value" *ngIf="selectedCoinTo?.decimals==4">
                  {{amountTo | number: '1.4-4'}}
            </ion-label>
              </ion-col>
             
            </ion-row>
          </div>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row class="bottom-row">
      <ion-col size = "5" style="text-align: start; padding-left: 10px;" *ngIf="selectedCoinFrom!=null && selectedCoinFrom.swapFeePercent>0">
        <span class="amount-received-label">
          Fee ({{selectedCoinFrom.swapFeePercent}}%): {{feeAmount| number:'1.4-4'}} {{selectedCoinFrom.symbol}}<br>
        </span>
        <!--<span class ="amount-received-value" *ngIf="selectedCoinTo?.decimals==2">
          {{selectedCoinTo?.symbol}} {{amountSwap | number: '1.2-2'}}
         </span>-->
        <span class ="amount-received-value" *ngIf="selectedCoinTo?.decimals==4">
         {{selectedCoinTo?.symbol}} {{amountSwap | number: '1.4-4'}}
        </span>
      </ion-col>
      <ion-col  class="ion-text-center">
        <ion-button [disabled]="availableAmountError" (click)="confirm()" class="confirm-button">
          <ion-label>CONFIRM</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
