import { WalletService } from './../../services/wallet.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, IonSelect, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { BaseComponent } from 'src/app/commons/base/base.component';
import { Coin, DatasessionService, Wallet } from 'src/app/services/datasession.service';
import { CommonService } from 'src/app/commons/commons.service';
import { TransferInput } from 'src/app/services/models/transfer';
import { UsersService } from 'src/app/services/users.service';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-transfer-item',
  templateUrl: './transfer-item.component.html',
  styleUrls: ['./transfer-item.component.scss'],
})
export class TransferItemComponent extends BaseComponent implements OnInit{
  //Vengono passati da fuori
  @Input() fromWallets: Array<Wallet> = [
    /*{
      description: 'Wallet Reward',      
      value: 'reward',
      maxAvailable:0,
      icon: null
    }*/
  ];

  @Input() toWallets: Array<Wallet> = [
    /*{
      text: 'Wallet Spot',
      value: 'spot',
      child: 'wallet-reward',
      maxAvailable:0,
      icon: null
    }*/
  ];
  @Input() coinId: string = 'USDT';
  @Input() maxAvailable: number = 0;
  availableAmountError:boolean = false;
  _amount?: number;
  set amount (value: number |undefined){
    this._amount = value;
    let t = setTimeout(()=>{
      this.onValueChange();
      clearTimeout(t);
    },80);

  } 
  get amount():number|undefined{
    if(this._amount!=null ){
      let val = parseFloat(this._amount.toFixed(4));
      return val;
    }
    return undefined;
  }

 inputIsOnFocus:boolean = false;
  //coinValues: Array<Coin> = new Array<Coin>();

  valuteMoney: string = '';
  data: any = {};
  currency: number = 0;
  currencyUSDT: number = 0;


  selectedFrom: Wallet|null = null;
  selectedTo: Wallet|null = null;
  selectedCoin: any|null =null;

  @Input() reverse:boolean = false;

  @ViewChild('inputSelectCoin') inputSelectCoin: IonSelect|undefined;
  @ViewChild("fromvalue",{static:true, read:ElementRef}) fromValueElement!:ElementRef;
  //toValuesFiltred: any[]=[];

  constructor(private modalController: ModalController,
      private _authService:AuthService,
      private _userServices:UsersService,
      private commonServices:CommonService,
      private dataSessionService:DatasessionService,
      private walletService:WalletService
  ) {
    super (_authService, dataSessionService, _userServices)
  }

  override async ngOnInit() {
    this.id="transferModal"
    this.selectedFrom = this.fromWallets[0];
    this.selectedTo = this.toWallets[0];
    this.coinsId.push(this.coinId);
    await super.ngOnInit();
    //this.coinValues.push(this.dataSessionService.coinValues[0]);
    this.selectedCoin = this.coins[0];
    //this.toValuesFiltred=this.toValues; 
    let wUser = await this._userServices.get(this._authService.loggedUser$.getValue()?.id);
    
      //TO DO
      /*let c = this.dataSessionService.getCoinsByIds([this.coinId])[0];
      this.maxAvailable = c.maxAvailable as number;*/
    console.log("transfer-item",this);  

  }
  
  /*fromchange(){
    this.toValuesFiltred=this.toValues.filter(item => item.child === this.selectedFrom.value);
    console.log(this.toValuesFiltred, this.selectedFrom.value)
  }*/
  switch(){
    let froms = this.fromWallets;
    this.fromWallets = this.toWallets;
    this.toWallets = froms;
    let oldSelection =this.selectedFrom;
    this.selectedFrom =this.selectedTo;
    this.selectedTo = oldSelection;
  }
  triggerSelectCoin($event: any) {
    console.log(this.inputSelectCoin);
    // TODO - Commentato perchè aprendo la select programmaticamente viene mostrata l'interfaccia errata
    // this.inputSelectCoin!.open();
  }

  cancel() {
    this.modalController.dismiss(null, 'cancel');
  }
  max(){

        this.amount = this.maxAvailable;
    }
  
  confirm() {
    let model = new TransferInput();
    model.walletTo= this.selectedTo?.id as string;
    model.walletFrom= this.selectedFrom?.id as string;
    model.amount = this.amount?.toFixed(6) as string;
    model.id = this.dataSessionService.loggedUser?.id as string;
    model.coin = this.coinId;
    this.walletService.transfer(model).then((r)=>{
      if(r.success)
        this.commonServices.showToast("transferconfirm","Operation succeed!",4000,"bottom","success")
      else
        this.commonServices.showToast("transfererror","Something bad appened! "+r.message,4000,"bottom","danger");
        this.dataSessionService.init();
        this.modalController.dismiss(null, 'confirm');
    
      }).catch((error)=>{
      this.commonServices.showToast("transfererror","Something bad appened! "+error,4000,"bottom","danger");
    });
  }
  onValueChange() {
    if(!this.inputIsOnFocus){
      this.availableAmountError = false;
      let v = parseFloat(this.fromValueElement.nativeElement.value as string).toFixed(6);
      if(parseFloat(v)>this.maxAvailable)
      {
        this.commonServices.showToast("AvailableAmountError","Insufficient available balance! Please enter again.",4000,"bottom","danger");
        this.availableAmountError = true;
      }
      this.fromValueElement.nativeElement.value = v.toString();
      this.currencyUSDT = this.selectedCoin?.conversionFunction(this.amount);
    }
    
  }
}

