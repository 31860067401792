import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpVerbs, NGHttpService } from './ng-http/ng-http.service';
import User from '../models/user';
import { DepositInput } from './models/deposit';
import { WithdrawInput } from './models/withdraw';
import ChangePwd from '../models/changPwd.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private endPoint: string = '/users';

  constructor(
    private readonly ngHttpService: NGHttpService,
    private readonly authService: AuthService,
  ) { }

  async list(params: any = {}): Promise<Array<User>> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}`, params, this.mapToModel, () => this.authService.getToken());
  }

  async get(id: any, params: any={}): Promise<User> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/${id}`, params, this.mapToModel, () => this.authService.getToken());
  }
  async checkValidUser(id: any, params: any={}): Promise<User> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/checkValidUser/${id}`, params, this.mapToModel, () => this.authService.getToken());
  }


  async verifyUser(username: string): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/verify/${username}`, null, null, () => this.authService.getToken());
  }



  async registerComplete(id: any, params: any={}): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/registerComplete/${id}`, params, null, () => this.authService.getToken());
  }

  async historyUserPool(id: any, tpool:any, params: any={}): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/historyUserPool/${id}/${tpool}`, params, null, () => this.authService.getToken());
  }

  async getCommunity(id: any, params: any={}): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/community/${id}`, params, null, () => this.authService.getToken());
  }

  async getCommunityKaspa(id: any, params: any={}): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/communityKaspa/${id}`, params, null, () => this.authService.getToken());
  }

  async getTransaction(id: any, params: any={}): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/gettransaction/${id}`, params, null, () => this.authService.getToken());
  }

  async dropNoActive(): Promise<any> {
    const params: any={}
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/dropNoActive`, params, null, () => this.authService.getToken());
  }

  async save(model: User): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.POST, this.endPoint, model.toObject(true), this.mapToModel, () => this.authService.getToken());
  }

  async update(model: User): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.PATCH, `${this.endPoint}/${model.id}`, model.toObject(true), this.mapToModel, () => this.authService.getToken());
  }
  async changePwd(model:ChangePwd){
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/changePwd` , model.toObject(true), this.mapToModel, () => this.authService.getToken());
  }
  // userId e address
  async saveAddress(body: any): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/insAddress/`, body, null, () => this.authService.getToken());
  }
  async delete(id: any): Promise<boolean> {
    return await this.ngHttpService.execute(HttpVerbs.DELETE, `${this.endPoint}/${id}`, null, null, () => this.authService.getToken());
  }

  async recovery(email: string): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/recoveryPassword/${email}`, null, null);
  }
  async forgotPWD(email: string, otp:string): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/forgotPWD/${email}/${otp}`, null, null);
  }
  /*-----------------------------------*/
  async deposit(model:DepositInput):Promise<any>{
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/request`, model, null, () => this.authService.getToken());
  }
  async withdraw(model:WithdrawInput):Promise<any>{
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/request`, model, null, () => this.authService.getToken());
  }
  mapToModel(item: any) {
    let model = User.fromObject(item, new User());
    return model;
  }

}
//https://gpttech.tboxlab.com:9001/c978e8a2-73e3-4e8e-bf97-951e65a8497d#/Users/UsersController_changePwd