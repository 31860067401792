import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
const evCache:any[] = [];
let prevDiff = -1;
let logEvents = false;
let self:any = undefined;
@Component({
  selector: 'app-slider-item',
  templateUrl: './slider-item.component.html',
  styleUrls: ['./slider-item.component.scss'],
})

export class SliderItemComponent  implements OnInit {

  constructor() { }
  @ViewChild("target",{static:true}) p?: ElementRef<HTMLDivElement>;
  @Input() fixedAreaWidth:number = 30;
  @Input() sliderAreaWidth:number = 30;
  @Input() fixedAreaMinWidth:number = 0;
  currentZoom:number = 1;
  top:number = 0;
  left:number = 0;


  startX:number = 0;
  startY:number  = 0 ;

  get fixedAreaMinWidthStr():string{
    return this.fixedAreaMinWidth.toString() + "px";
  }
  get fixedAreaWidthStr():string{
    return this.fixedAreaWidth.toString() + "px";
  }
  get sliderAreaWidthStr():string{
    return this.sliderAreaWidth.toString() + "px";
  }

  ngOnInit() {
    self = this;
   /*The pointerdown event is fired when a pointer becomes active. 
      For mouse, it is fired when the device transitions from no buttons pressed to at least one button pressed. For touch, it is fired when physical contact is made with the digitizer. For pen, it is fired when the stylus makes physical contact with the digitizer.*/
      this.p!.nativeElement.onpointerdown = this.pointerdownHandler;
      //The pointermove event is fired when a pointer changes coordinates, and the pointer has not been canceled by a browser touch-action.
      this.p!.nativeElement.onpointermove = this.pointermoveHandler;
    
      // Use same handler for pointer{up,cancel,out,leave} events since
      // the semantics for these events - in this app - are the same.
      this.p!.nativeElement.onpointerup = this.pointerupHandler;
      //this.p!.nativeElement.onpointercancel = this.pointerupHandler;
      //this.p!.nativeElement.onpointerout = this.pointerupHandler;
      this.p!.nativeElement.onpointerleave = this.pointerupHandler;
  }

  log(prefix:any, ev:any) {
    if (!logEvents) return;
    
    const s =
      `${prefix}:<br>` +
      `  pointerID   = ${ev.pointerId}<br>` +
      `  pointerType = ${ev.pointerType}<br>` +
      `  isPrimary   = ${ev.isPrimary}`;
    console.log(`${s}<br>`);
  }
  removeEvent(ev:any) {
    // Remove this event from the target's cache
    const index = evCache.findIndex(
      (cachedEv) => cachedEv.pointerId === ev.pointerId,
    );
    evCache.splice(index, 1);
  }
  pointerupHandler(ev:any) {
    console.log(ev.type, ev);
    self.startX = ev.x;
    self.startY = ev.y;
    // Remove this pointer from the cache and reset the target's
    // background and border
    self.removeEvent(ev); 
    // If the number of pointers down is less than two then reset diff tracker
    if (evCache.length < 2) {
      prevDiff = -1;
    }
  }
    pointerdownHandler(ev:any) {
    // The pointerdown event signals the start of a touch interaction.
    // This event is cached to support 2-finger gestures
    evCache.push(ev);
    self.startX = ev.x;
    self.startY = ev.y;
    self.log("pointerDown", ev);
  }
  pointermoveHandler(ev:any) {
    // This function implements a 2-pointer horizontal pinch/zoom gesture.
    //
    // If the distance between the two pointers has increased (zoom in),
    // the target element's background is changed to "pink" and if the
    // distance is decreasing (zoom out), the color is changed to "lightblue".
    //
    // This function sets the target element's border to "dashed" to visually
    // indicate the pointer's target received a move event.
    //console.log("pointerMove", ev);
    //ev.target.style.border = "dashed";
  
    // Find this event in the cache and update its record with this event
  
    const index = evCache.findIndex(
      (cachedEv) => cachedEv.pointerId === ev.pointerId,
    );
    evCache[index] = ev;
  
    // If two pointers are down, check for pinch gestures
    if (evCache.length === 2) {
      // Calculate the distance between the two pointers
      const curDiff = Math.abs(evCache[0].clientX - evCache[1].clientX);
  
      if (prevDiff > 0) {
        if (curDiff > prevDiff) {
          // The distance between the two pointers has increased
          self.log("Pinch moving OUT -> Zoom in", ev);

        }
        if (curDiff < prevDiff) {
          // The distance between the two pointers has decreased
          self.log("Pinch moving IN -> Zoom out", ev);

        }
      }
  
      // Cache the distance for the next move event
      prevDiff = curDiff;
    }
    //only one pointer
    else if (evCache.length === 1){
      const curDiffX = Math.abs(evCache[0]?.clientX);
      const curDiffY = Math.abs(evCache[0]?.clientY);
      
      if (curDiffX > self.startX) {
        console.log("moving right")
        let x = self.p!.nativeElement.scrollLeft;
        self.p!.nativeElement.scrollLeft = x - (curDiffX - self.startX);
      }
      if (curDiffX < self.startX) {
        console.log("moving left")
        let x = self.p!.nativeElement.scrollLeft;
        self.p!.nativeElement.scrollLeft = x + ( self.startX - curDiffX);
      }
      if (curDiffY < self.startY) {
        console.log("moving top")
        let y = self.p!.nativeElement.scrollTop;
        self.p!.nativeElement.scrollTop = y - (curDiffY - self.startY);
      }
      if (curDiffY > self.startY) {
        console.log("moving down")
        let y = self.p!.nativeElement.scrollTop;
        self.p!.nativeElement.scrollTop = y + (self.startY - curDiffY);
      }
      self.startX = evCache[0].clientX;
      self.startY = evCache[0].clientY;
    }
  }
}
