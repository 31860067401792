import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  /**
   * Store a simple item
   * @param key
   * @param value
   * @returns
   */
  setItem(key: string, value: string): void {
    return window.sessionStorage.setItem(key, value);
  }

  /**
   * Get a simple item value by key
   * @param key
   * @returns
   */
  getItem(key: string): string|null {
    return window.sessionStorage.getItem(key);
  }

  /**
   * Store an object (will be encoded to a string)
   * @param key
   * @param value
   * @returns
   */
  setObject(key: string, value: any): void {
    return this.setItem(key, JSON.stringify(value));
  }

  /**
   * Get a stored object by key (will be parsed to a plain object)
   * @param key
   * @returns
   */
  getObject(key: string): any {
    const data: any = this.getItem(key);
    if (!!data === false) { return null; }
    return JSON.parse(data);
  }

  /**
   * Remove an item by key
   * @param key
   * @returns
   */
  removeItem(key: string): void {
    return window.sessionStorage.removeItem(key);
  }

  /**
   * Clear all store items
   * @returns
   */
  clear(): void {
    return window.sessionStorage.clear();
  }

}
