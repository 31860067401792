import BaseModel from "./base.model";

export default class WalletUser extends BaseModel {
    idUser: string|null=null;
    gptReward: number=0;
    gptAvailable=0;
    eur: number=0;
    usdt: number=0;
    gptO: number= 0;
    kas:number = 0;
    kasReward:number = 0;
    btc:number = 0;
    kgpm:number = 0;
    bgpm:number = 0;
    dailyK:number = 0;
    monthlyK:number = 0;
    totalKasR:number = 0;
    wMinUSDT:number=0; //USDT sul wallet mining
    totalCommunityKAS:number=0;
}
