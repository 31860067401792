import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private toastController: ToastController, private alertController:AlertController) { }

  async showToast(id:string, message: string, duration: number, position: any="bottom", color:string = "light") {
    const toast = await this.toastController.create({
      id: id,
      message: message,
      duration: duration,
      color: color,
      position: position // Puoi modificare la posizione del toast se necessario
    });
    toast.present();
  }
  
  async presentAlert(id:string, message: string, header: string) {
    const alert = await this.alertController.create({
      id: id,
      header: header,
      message: message,
      buttons: ['OK'],
      cssClass: 'modal-max-width-375'
    });

    await alert.present();
  }
}
