import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import WalletUser from '../models/userwallet.model';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject, interval, map, mergeMap } from 'rxjs';
import Decimal from 'decimal.js';
import { HttpVerbs, NGHttpService } from './ng-http/ng-http.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GPTService {

  public GPT: number = 0;
  public GPT$: BehaviorSubject<number> = new BehaviorSubject(0);
  private fakeGPT = 64.001;
  private fakeEURUSD = 1.0930;

  // public loggedUser$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(
    private readonly storageService: StorageService,
    private readonly ngHttpService: NGHttpService,
  ) {
    this.initGPT();
    this.readGPT();
  }


  async getValueGPT() {
    let value: number = 0;
    const gold = await this.ngHttpService.execute(HttpVerbs.GET, environment.readGPT, {});
    
    if(gold == null)
      console.error(environment.readGPT," is returning null!");
    return gold==null?0:gold.gpt;
  }

  readGPT() {
    setTimeout(() => {
      this.getValueGPT().then((value) => {
        this.GPT = value;
        this.GPT$.next(this.GPT);
      });
    }, 1000);
  }

  async initGPT() {
    this.GPT = await this.getValueGPT();
    this.GPT$.next(this.GPT);
  }

  public getGPT() {
    return this.GPT;
  }

  getEUR2USD() {
    return this.fakeEURUSD;
  }

  getSummaryEUR2USD(usd: number) {
    return usd * this.fakeEURUSD;
  }

  convertUsdt2GPT(usdt: number) {

    if (usdt > 0) {
      return +(usdt / this.GPT).toFixed(4)
    }
    return 0;
  }

  convertGPTToUsdt(gpt: number) {
    if (gpt > 0) {
      return (gpt * this.GPT)
    }
    return 0;
  }

  summaryUSDWallet(userwallet: WalletUser) {
    return this.convertGPTToUsdt(userwallet.gptAvailable!) + userwallet.usdt! + (userwallet.eur! * this.fakeEURUSD);
  }

}
